import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import Order from 'views/admin/order';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import RegisterCentered from 'views/auth/register';
import PasswordCentered from 'views/auth/passwordRecovery';


const routes = [
  {
    name: 'Рабочий кабинет',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Заявки в работе',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: <DataTables />,
  },
  {
    name: 'Личный кабинет',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },
  {
    name: 'Детали заявки',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables/:id',
    component: <Order />,
  },
  {
    name: 'Войти',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  },
  {
    name: 'Зарегистрироваться',
    layout: '/auth',
    path: '/register',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <RegisterCentered />,
  },
  {
    name: 'Восстановление пароля',
    layout: '/auth',
    path: '/password-recovery',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <PasswordCentered />,
  },

];

export default routes;
