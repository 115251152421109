import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
// Chakra imports

export default function Main() {

  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  return (

    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />

        <Route path="/admin/*" element={
    localStorage.getItem("loginUser") == null ? (
      <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
    ) : (
      <Navigate to="/auth/sign-in" />
    )
  }
/>
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
