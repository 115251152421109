import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, Flex, Heading } from '@chakra-ui/react';

// Предполагается, что данные можно получать из API или заранее передать как пропсы.
import Orders from 'views/admin/dataTables/variables/orders.json'; // Это ваш JSON с заявками

export default function OrderDetails() {
  const { id } = useParams(); // Получаем id заявки из URL
  const order = Orders.find((o) => o.id === id); // Находим заявку по id

  // Если заявка не найдена, отображаем сообщение
  if (!order) {
    return (
      <Box p="20px">
        <Text>Заявка с ID {id} не найдена.</Text>
      </Box>
    );
  }

  return (
    <Box p="20px">
      <Heading mb="4">Детали заявки {id}</Heading>

      {/* Информация о заявке */}
      <Flex flexDirection="column" gap="10px">
        <Text><strong>Тип:</strong> {order.type}</Text>
        <Text><strong>Страна отправителя:</strong> {order.sender_country}</Text>
        <Text><strong>Платежная система:</strong> {order.system_type}</Text>
        <Text><strong>Назначение платежа:</strong> {order.payment_purpose}</Text>
        <Text><strong>Сумма:</strong> {order.payment_amount}</Text>
        <Text><strong>Валюта:</strong> {order.currency}</Text>
        <Text><strong>Статус:</strong> {order.status}</Text>
      </Flex>
    </Box>
  );
}
