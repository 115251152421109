import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import RequestFormSteps from './RequestFormSteps'; // Подключаем компонент шагов формы

const RequestModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure(); // Модальное окно подтверждения
  const [step, setStep] = useState(1); // Состояние для отслеживания шагов
  const [formData, setFormData] = useState({
    clientType: '',
    fillOption: '',
    contract: '',
    invoice: '',
    swift: '',
    bankCountry: '',
    bankName: '',
    tnved: '',
    paymentPurpose: '',
    currency: '',
    amount: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    console.log('Form Data Submitted:', formData);
    onClose(); // Закрыть основное модальное окно с формой
    onSuccessOpen(); // Открыть модальное окно подтверждения
    resetForm(); // Сбросить форму
  };

  // Функция для сброса данных формы
  const resetForm = () => {
    setFormData({
      clientType: '',
      fillOption: '',
      contract: '',
      invoice: '',
      swift: '',
      bankCountry: '',
      bankName: '',
      tnved: '',
      paymentPurpose: '',
      currency: '',
      amount: ''
    });
    setStep(1); // Вернуть на первый шаг
  };

  return (
    <>
      <Button variant='brand' onClick={onOpen}>
        Завести заявку
      </Button>

      {/* Модальное окно с формой */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {step === 1 && 'Выберите тип клиента'}
            {step === 2 && 'Выберите способ заполнения'}
            {step === 3 && formData.fillOption === 'auto' && 'Загрузите документы'}
            {step === 3 && formData.fillOption === 'manual' && 'Заполните форму вручную'}
          </ModalHeader>

          <ModalBody>
            <RequestFormSteps
              step={step}
              formData={formData}
              handleChange={handleChange}
              handleFileUpload={handleFileUpload}
              nextStep={nextStep}
              prevStep={prevStep}
              handleSubmit={handleSubmit}
              setFormType={(type) => setFormData({ ...formData, clientType: type })}
            />
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>

      {/* Модальное окно с подтверждением отправки */}
      <Modal isOpen={isSuccessOpen} onClose={onSuccessClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Заявка успешно сохранена!</ModalHeader>
          <ModalBody>
            <Text>Ваша заявка успешно сохранена и направлена в работу.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={onSuccessClose}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestModal;
