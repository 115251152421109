import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.jpg";
import { RiUser3Line, RiStoreLine } from "react-icons/ri"; 

function Register() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [formType, setFormType] = useState("user"); 
  const handleSelectFormType = (type) => {
    setFormType(type); 
  };


  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Зарегистрироваться
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Выберите ваш логин и пароль чтобы войти на платформу.
          </Text>
        </Box>

        <Flex mb="24px" justifyContent="space-between" w="100%">
          <Button
            leftIcon={<RiUser3Line />} // Иконка пользователя
            w="49%"
            variant={formType === "user" ? "solid" : "outline"} // По умолчанию выбрана "user"
            onClick={() => handleSelectFormType("user")}
          >
            Я Клиент
          </Button>
          <Button
            leftIcon={<RiStoreLine />} // Иконка магазина
            w="49%"
            variant={formType === "seller" ? "solid" : "outline"}
            onClick={() => handleSelectFormType("seller")}
          >
            Я Агент
          </Button>
        </Flex>

        {/* Форма, отображаемая в зависимости от выбранной кнопки */}
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            {/* Поля для Личного кабинета */}
            {formType === "user" && (
              <>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Введите ваш ИНН
                </FormLabel>
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Минимум 8 символов"
                    mb="24px"
                    size="lg"
                    variant="auth"
                  />
                                 <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Введите ваш Email
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="mail@test.ru"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
              </>
            )}

            {formType === "seller" && (
              <>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Введите ваш Телефон
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="+799999999999"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                />
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Введите Фамилию, Имя и Отчество
                </FormLabel>
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Иванов Иван Иванович"
                    mb="24px"
                    size="lg"
                    variant="auth"
                  />
                  <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Введите ваш Email
                </FormLabel>
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="mail@test.ru"
                    mb="24px"
                    size="lg"
                    variant="auth"
                  />
              </>
            )}

            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              Зарегистрироваться
            </Button>
          </FormControl>

        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Register;