import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Flex

} from '@chakra-ui/react';

const RequestFormSteps = ({
  step,
  formData,
  handleChange,
  handleFileUpload,
  nextStep,
  prevStep,
  handleSubmit,
  setFormType,
}) => {
  return (
    <>
      {/* Шаг 1: Выбор типа клиента */}
      {step === 1 && (
    <Box>
    <Flex justify="center" align="center" gap={4} mb={4}>
      <Button
        size="lg" // Размер кнопки
        width="200px" // Ширина кнопки
        height="200px" // Высота кнопки для квадрата
        onClick={() => {
          setFormType('send');
          nextStep();
        }}
      >
        Я отправляю деньги
      </Button>

      <Button
        size="lg" // Размер кнопки
        width="200px" // Ширина кнопки
        height="200px" // Высота кнопки для квадрата
        onClick={() => {
          setFormType('receive');
          nextStep();
        }}
      >
        Я получаю деньги
      </Button>
    </Flex>
  </Box>
      )}

      {/* Шаг 2: Способ заполнения */}
      {step === 2 && (
   <Box>
   <Flex direction="column" gap={4}> {/* Устанавливаем отступ между кнопками */}
     <Button
       colorScheme="teal"
       width="100%"  // Делаем кнопки одинаковой ширины
       onClick={() => {
         handleChange({ target: { name: 'fillOption', value: 'auto' } });
         nextStep();
       }}
     >
       Заполним сами, загрузите документы в систему
     </Button>

     <Button
       colorScheme="blue"
       width="100%"  // Делаем кнопки одинаковой ширины
       onClick={() => {
         handleChange({ target: { name: 'fillOption', value: 'manual' } });
         nextStep();
       }}
     >
       Заполнить вручную
     </Button>
   </Flex>
 </Box>
      )}

      {/* Шаг 3: Загрузка документов */}
      {step === 3 && formData.fillOption === 'auto' && (
        <Box>
        
          <FormControl mb={4}>
            <FormLabel>Договор (Contract)</FormLabel>
            <Input type="file" name="contract" onChange={handleFileUpload} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Счёт (Invoice)</FormLabel>
            <Input type="file" name="invoice" onChange={handleFileUpload} />
          </FormControl>
        </Box>
      )}

      {/* Шаг 3: Вручную заполнить форму */}
      {step === 3 && formData.fillOption === 'manual' && (
        <Box>
         

          <FormControl mb={4}>
            <FormLabel>Договор (Contract)</FormLabel>
            <Input type="file" name="contract" onChange={handleFileUpload} />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Счёт (Invoice)</FormLabel>
            <Input type="file" name="invoice" onChange={handleFileUpload} />
          </FormControl>

          {/* Дополнительные инпуты для ручного ввода */}
          <FormControl mb={4}>
            <FormLabel>SWIFT / SEPA / CIPS / CFC Банка отправителя</FormLabel>
            <Input name="swift" value={formData.swift} onChange={handleChange} />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Страна Банка отправителя</FormLabel>
            <Select name="bankCountry" value={formData.bankCountry} onChange={handleChange}>
              <option value="Россия">Россия</option>
              <option value="Германия">Германия</option>
              <option value="США">США</option>
            </Select>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Наименование Банка отправителя</FormLabel>
            <Input name="bankName" value={formData.bankName} onChange={handleChange} />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>КОДЫ ТНВЭД</FormLabel>
            <Input name="tnved" value={formData.tnved} onChange={handleChange} />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Назначение платежа</FormLabel>
            <Input name="paymentPurpose" value={formData.paymentPurpose} onChange={handleChange} />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Валюта</FormLabel>
            <Select name="currency" value={formData.currency} onChange={handleChange}>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="RUB">RUB</option>
            </Select>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Сумма платежа</FormLabel>
            <Input name="amount" value={formData.amount} onChange={handleChange} />
          </FormControl>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" mt={6}>
        {step > 1 && (
          <Button variant="outline" onClick={prevStep}>
            Назад
          </Button>
        )}
        {step === 3 && (
          <Button colorScheme="green" onClick={handleSubmit}>
            Сохранить заявку и направить в работу
          </Button>
        )}
      </Box>
    </>
  );
};

export default RequestFormSteps;
