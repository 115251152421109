export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "Контрагент",
    accessor: "name",
  },
  {
    Header: "Прогресс",
    accessor: "progress",
  },
  {
    Header: "Стоимость",
    accessor: "quantity",
  },
  {
    Header: "Дата",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "Тип",
    accessor: "type",
  },
  {
    Header: "Страна",
    accessor: "sender_country",
  },
  {
    Header: "Платежная система",
    accessor: "system_type",
  },
  {
    Header: "Назначение платежа",
    accessor: "payment_purpose",
  },
  {
    Header: "Сумма",
    accessor: "payment_amount",
  },
  {
    Header: "Валюта",
    accessor: "currency",
  },
  {
    Header: "Статус",
    accessor: "status",
  },
];


export const columnsDataComplex = [
  {
    Header: "Тип",
    accessor: "type",
  },
  {
    Header: "Страна",
    accessor: "sender_country",
  },
  {
    Header: "Платежная система",
    accessor: "system_type",
  },
  {
    Header: "Назначение платежа",
    accessor: "payment_purpose",
  },
  {
    Header: "Сумма",
    accessor: "payment_amount",
  },
  {
    Header: "Валюта",
    accessor: "currency",
  },
  {
    Header: "Статус",
    accessor: "status",
  },
];
