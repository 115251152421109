// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";


// Assets
export default function GeneralInformation(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Информация об организации
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px'>
      Полное наименование:
      </Text>
      <Text color={textColorPrimary} fontSize='md' me='26px'>
      ---
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px'>
      Краткое наименование:
      </Text>
      <Text color={textColorPrimary} fontSize='md' me='26px'>
      ---
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px'>
      ОГРН:
      </Text>
      <Text color={textColorPrimary} fontSize='md' me='26px'>
      ---
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px'>
      Адрес местонахождения:
      </Text>
      <Text color={textColorPrimary} fontSize='md' me='26px'>
      ---
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px'>
      ФИО единоличного исполнительного органа:
      </Text>
      <Text color={textColorPrimary} fontSize='md' me='26px'>
      ---
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px'>
      ОКВЭД:
      </Text>
      <Text color={textColorPrimary} fontSize='md' me='26px'>
      ---
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px'>
      ФИО учредителей:
      </Text>
      <Text color={textColorPrimary} fontSize='md' me='26px'>
      ---
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px'>
      Дополнительные коды:
      </Text>
      <Text color={textColorPrimary} fontSize='md' me='26px'>
      ---
      </Text>

    </Card>
  );
}
